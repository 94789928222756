.alert {
  font-size: 1.7rem;
  color: #fff;
  font-weight: 500;
  transition: all 0.3s;
  align-items: center;
  position: fixed;
  bottom: 3rem;
  margin: auto;
  width: fit-content;
  padding: 0.8rem 2rem;
  justify-content: center;
  text-align: center;
  border-radius: 3px;
  @include respond(tab-port) {
    z-index: 99;
    margin: auto;
    bottom: 2rem;
  }
}

.failure {
  background: #c81515;
}
.success {
  background: #5fb95c;
}
.disable {
  display: none !important;
}
.back {
  display: flex;
  align-items: center;
  font-size: medium;
  font-weight: 600;
  cursor: pointer;
  margin: 2rem auto 2rem auto;
  p {
    border: 1px solid rgba(128, 128, 128, 0.286);
    border-radius: 5px;
    padding-right: 0.7rem;
    padding-left: 0.7rem;
    color: rgba(58, 66, 104, 0.786) !important;
  }
}
// Loader
.loader {
  z-index: 99;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  display: block;
  margin: 15px auto;
  position: relative;
  background: #fff;
  box-shadow: -24px 0 #fff, 24px 0 #fff;
  box-sizing: border-box;
  animation: shadowPulse 1.5s linear infinite;
}

@keyframes shadowPulse {
  33% {
    background: #fff;
    box-shadow: -24px 0 #ff3d00, 24px 0 #fff;
  }
  66% {
    background: #ff3d00;
    box-shadow: -24px 0 #fff, 24px 0 #fff;
  }
  100% {
    background: #fff;
    box-shadow: -24px 0 #fff, 24px 0 #ff3d00;
  }
}
.containerSpinner {
  align-items: center;
  background: #000000b7;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  margin: auto;
  color: #fff;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 100;
}
.errorAlert {
  color: rgb(225, 47, 47);
}
.errorAlert::first-letter,
.signupAlert::first-letter {
  text-transform: uppercase;
}
.loaderWrapper {
  padding: 2rem;
  width: 50rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  font-weight: 400;
  font-size: 1.4rem;
  justify-content: center;
  height: 30rem;
  border-radius: 5px;
  color: #000;
  background-color: #fff;
}

.alert-position {
  position: fixed;
  bottom: 0.5rem;
  z-index: 100;
  left: 0;
  right: 0;
  margin: auto;
  width: fit-content;
  padding: 0.8rem 2rem 0.8rem 2rem;
  font-size: 1.6rem !important;
}
