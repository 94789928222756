@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300&family=Poppins:wght@200;300&family=Source+Sans+Pro:wght@300;400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300&family=Poppins:wght@200;300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300&family=Poppins:wght@200;300&display=swap");

body {
  font-family: "Source Sans Pro", "Inter", "Poppins", sans-serif;
  font-weight: 400;
  line-height: 1.7;
  box-sizing: border-box;
  padding: 0;
  scroll-behavior: smooth;
  margin: 0;
}
