@import "../main.scss";
@import "./location.scss";
@import "./loginOrSignUp.scss";

.header {
  position: sticky;
  display: flex;
  padding: 1rem;
  height: 8rem;
  justify-content: space-around;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  z-index: 70;
  width: 100%;
  background: #fff;
  left: 0;
  right: 0;
  top: 0;
  @include respond(tab-port) {
    flex-direction: column-reverse;
    height: auto;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0);
  }

  .header-left-section {
    @include display-flex-align-center;
    justify-content: space-between;
    width: 60%;

    @include respond(tab-port) {
      width: 100%;
      margin-top: 1rem;
    }

    .logo-container {
      display: flex;
      height: 5rem;
      align-items: center;
      max-height: 90%;
      width: 17rem;
      max-width: 20%;

      @include respond(tab-port) {
        display: none;
      }
    }
    .search-bar {
      position: relative;
      @include display-flex-align-center;
      justify-content: space-between;
      border-radius: 5px;
      width: 65%;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
      @include respond(tab-port) {
        width: 100%;
      }
      input[type="search"] {
        border: none;
        width: 85%;
        margin: auto;
        background-color: $white;
        outline: none;
        font-size: $font-sm;
        padding-left: 0.2rem;

        @include respond(tab-port) {
          width: 80%;
        }
      }

      .search-results {
        position: absolute;
        width: 100%;
        border-radius: 5px;
        background: white;
        color: black;
        z-index: 20;
        padding: 0 1rem 0 1rem;
        box-shadow: 0 2px 2px rgb(0 0 0 / 20%);
        overflow: auto;
        top: 5rem;

        @include respond(tab-port) {
          height: auto;
          max-height: 24.5rem;
        }
        .results {
          padding: 1rem;
          cursor: pointer;
          font-size: $font-sm;
          border-top: 1px solid #c2c2c2;

          a {
            color: rgba(73, 67, 67, 0.919);

            &:hover {
              color: rgb(72, 72, 72);
            }
          }
        }
      }
    }

    .cta-search {
      padding: 4.5px 11.5px 0.5px 0;
      border: none;
      border-radius: 0.3rem;
      @include respond(tab-port) {
        padding: 1.3rem 1.3rem 0.6rem 1.3rem;
      }

      span {
        font-size: $font-big-1;
        color: $light-grey;
      }
    }
  }
  .header-right-section {
    width: 45%;
    @include display-flex-align-center;
    justify-content: space-evenly;
    @include respond(tab-port) {
      width: 100%;
      justify-content: space-between;
    }

    span {
      margin-right: 2%;
      font-size: $font-med;
    }
    .location-container {
      display: flex;
      text-align: left;
      width: 35%;
      align-items: center;
      @include respond(tab-port) {
        width: 75%;
      }
    }
    .location-bar-container {
      @include display-flex-align-center;
      border-radius: 5px;
      transition: all 2s;
      width: 80%;
      overflow: hidden;
      color: #181818;
      white-space: nowrap;
      cursor: pointer;
      p {
        cursor: pointer;
        font-size: $font-med;
        color: #181818;
        overflow: hidden;
        text-overflow: ellipsis;

        @include respond(tab-port) {
          font-size: 1.8rem;
        }
      }
    }
    .caret-down {
      margin-left: 2%;
      font-size: 2rem;
      color: #181818d1;
      cursor: pointer;
    }
    .login-link-btn-container {
      p {
        cursor: pointer;
        text-decoration: none;
        font-size: $font-med;
        color: rgb(24, 24, 24);

        @include respond(tab-port) {
          font-size: 1.8rem;
        }
      }
    }

    .cart-link-btn-container {
      @include display-flex-align-center;
      background: #ff772f;
      color: #fff;
      width: 11rem;
      text-align: center;
      cursor: pointer;
      padding: 0.9rem;
      font-size: 1.8rem;
      justify-content: center;
      border-radius: 0.5rem;
      box-shadow: 1px 4px 15px rgba(0, 0, 0, 0.16);
      @include respond(tab-port) {
        z-index: 1;
        position: fixed;
        color: $white;
        background-color: #ff5700;
        bottom: 0.5rem;
        justify-content: center;
        border-radius: 5px;
        border: 0;
        box-shadow: 1px 4px 15px rgba(0, 0, 0, 0.16);
      }

      .cart-ico {
        font-size: 1.8rem;
        stroke-width: 0.7px;
      }
      .cart-items {
        padding-left: 1rem;
      }
    }
  }
}
.fix {
  position: fixed !important;
}
