@mixin respond($breakpoint) {
  @if $breakpoint == phone {
    @media only screen and (max-width: 37.5em) {
      @content;
    } //600px
  }
  @if $breakpoint == tab-port {
    @media only screen and (max-width: 56.25em) {
      @content;
    } //900px
  }
  @if $breakpoint == tab-land {
    @media only screen and (max-width: 68em) {
      @content;
    } //1200px
  }
  @if $breakpoint == laptop {
    @media only screen and (max-width: 98em) {
      @content;
    } //1200px
  }
  @if $breakpoint == big-desktop {
    @media only screen and (min-width: 112.5em) {
      @content;
    } //1800
  }
  @if $breakpoint ==v-big-desktop {
    @media only screen and (min-width: 140.25em) {
      @content;
    } //1800
  }
}

//
@mixin incr-decr {
  .incr-decr-product {
    display: flex;
    color: #47b752;
    justify-content: space-evenly;
    border-radius: 5px;
    align-items: center;
    max-width: 90%;
    border: 1px solid #47b752;
    height: 35px;
    transition: all 1s;
    padding: 0.1rem 0.2rem;
    .value {
      font-size: $default-font-size;
      user-select: none;
    }
    @include respond(tab-port) {
      margin-top: 1rem;
    }
    .incr-decr-btn {
      color: #47b752;
      background-color: transparent;
      font-size: 1.8rem;
      padding: 0.8rem 1rem 0.8rem 1rem;
      user-select: none;
      border: none;
      cursor: pointer;
      .minus {
        font-size: 2rem;
      }
    }
  }
}

@mixin defaultImg {
  img {
    width: 100%;
    height: 100%;
  }
}
@mixin display-flex-align-center {
  display: flex;
  align-items: center;
}
