@import "../main.scss";
.cart {
  width: 100%;
  height: 100%;
  transition: all 0.5s;

  .main {
    position: relative;
    width: 100%;
    position: fixed;
    overflow: auto;
    padding-bottom: 25rem;
    transition: all 1s;
    height: 100%;
    @include respond(tab-land) {
      position: unset;
      flex-direction: column;
      padding-bottom: 0;
    }
  }
  .cart-container {
    border: 1px solid rgba(112, 112, 112, 0.09);
    box-shadow: 2px 5px 9px rgba(0, 0, 0, 0.16);
    flex-direction: column;
    width: 65%;
    display: block;
    margin-left: 2.5%;
    transition: all 0.1s;
    @include respond(tab-land) {
      position: unset;
      width: 95%;
    }
    .cart-header {
      text-transform: uppercase;
      background-color: #ffffff;
      font-family: inherit;
      padding: 1.5rem;
      justify-content: space-evenly;
      font-size: $l3-font-size;
      color: #3f3f3f;
      font-weight: 500;
      text-align: left;
    }

    .cart-body {
      transition: all 1s;
      background-color: #ffffff;
      display: flex;
      flex-wrap: wrap;
      margin: 2rem auto;
      align-items: flex-start;
      justify-content: space-between;
      min-height: 42rem;
      @include respond(tab-land) {
        margin: 5rem auto;
      }

      .empty-cart-img {
        display: flex;
        margin: auto;
        flex-direction: column;
        width: 43%;

        @include defaultImg;
      }
      .product-in-cart {
        position: relative;
        display: flex;

        padding: 1rem;
        align-items: center;
        background-color: #ffffff;
        font-size: 1.5rem;
        justify-content: center;
        width: 50%;
        margin-bottom: 5rem;

        @include respond(tab-land) {
          width: 40%;
        }
        @include respond(tab-port) {
          width: 100%;
        }
        .details {
          width: 40%;
          margin-right: 2rem;
          @include respond(tab-land) {
            width: 80%;
          }
          .title {
            font-weight: 500;
            color: #2a2a2ae7;
          }
          .quant {
            font-weight: 500;
            color: #404040b7;
          }
          .price {
            font-weight: bold;
            color: rgba(60, 60, 60, 0.903);
          }
        }

        .img-container {
          width: 20%;
          border-radius: 5px;
          margin-right: 2rem;

          @include respond(tab-land) {
            width: 15%;
          }
          @include respond(tab-port) {
            width: 25%;
          }
          @include defaultImg;
        }
      }
      @include incr-decr;
    }
  }
}
.pricing-container {
  border: 1px solid rgba(112, 112, 112, 0.09);
  box-shadow: 2px 5px 9px rgba(0, 0, 0, 0.16);
  flex-direction: column;
  width: 25%;
  display: block;
  margin: auto auto auto 4%;
  overflow: auto;
  right: 5rem;
  top: 14.6rem;
  position: fixed;
  @include respond(tab-land) {
    margin: 2rem auto;
    position: unset;
    width: 95%;
  }
  .pricing-header {
    background-color: #ffffff;
    display: flex;
    padding: 1.5rem;
    justify-content: space-evenly;
    font-size: $l3-font-size;
    color: #3f3f3f;
    font-weight: 500;
    text-align: center;
  }
  .pricing-body {
    margin-top: 2rem;
    background-color: #ffffff;
    justify-content: space-evenly;
    font-size: 1.8rem;
    text-align: left;
    font-weight: 500;
    ul {
      display: flex;
      flex-wrap: wrap;
    }
  }

  .container-price {
    display: flex;
    justify-content: space-between;
    width: 85%;
    margin: auto auto 2rem auto;
    color: #404040;
  }
  .green {
    text-decoration: to-upper-case($string: "");
    font-weight: 500;
    color: #149414;
  }
  .total {
    font-size: 2rem;
    font-weight: 500;
  }
  .save {
    text-align: center;
    font-weight: 500;
    color: rgba(8, 129, 8, 0.893);
  }
}
.empty-cart {
  font-size: 3rem;
  width: 100%;
  margin: auto;
  text-align: center;
}

.remove {
  position: absolute;
  background-color: transparent;
  border: none;
  top: 3rem;
  left: 25rem;
  font-size: 2rem;
  cursor: pointer;
  outline: none;
  color: rgba(230, 38, 38, 0.93);
}
.dot-seprator {
  width: 85%;
  border-radius: 50%;
  margin: auto;
  display: flex;
  border: 0.1px dotted rgba(97, 105, 95, 0.527);
}
.product-price-with-btn-cart {
  display: flex;
  justify-content: center;

  @include respond(tab-port) {
    flex-direction: column;
  }
}

.checkout-link-btn-container {
  display: flex;
  border: 1px solid #47b752;
  position: relative;
  background-color: #47b752;
  color: $white;
  text-align: center;
  margin: 1rem auto;
  cursor: pointer;
  justify-content: center;
  margin: auto;
  font-size: 1.8rem;
  align-items: center;
  border-radius: 1px;
  padding: 1rem;
  margin: auto;
  @include respond(tab-port) {
    z-index: 5;
    color: $white;
    border: 1px solid #47b752;
    background-color: #47b752;
    bottom: 1rem;
    justify-content: center;
    width: 100%;
    border-radius: 5px;
    box-shadow: 1px 4px 15px rgba(0, 0, 0, 0.16);
  }
}
.checkout-container {
  padding: 2rem;
  margin: auto;
}
.btn-and-price {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
