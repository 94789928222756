.before-footer {
  width: 80%;
  margin: auto;

  .caption {
    font-size: $default-font-size;
    text-align: center;
  }
  .resp-images {
    display: flex;
    width: 100%;
    margin: auto;

    .img-container {
      margin: auto;
      width: 25%;
    }

    img {
      width: 100%;
      height: 100%;
    }
  }
}
