@import "../main.scss";

.slide-container {
  display: flex;
  margin: 2rem auto;
  width: 80%;
  @include respond(tab-port) {
    width: 90%;
  }

  .slides-container {
    display: flex;
    height: 30rem;
    max-height: 30rem;

    @include respond(tab-port) {
      height: auto;
      width: 100%;
    }
    .slides {
      width: 150rem;
      max-width: 100%;
    }
  }
}

// .container {
//   position: relative;
// }
// .productsContainerSlide {
//   position: relative;
//   justify-content: space-between;
//   overflow: hidden;
// }

// .slide {
//   width: 300px;
//   height: 200px;
//   border-radius: 0.2rem;
//   padding: 1rem;
//   margin: 1rem;
//   transition: all 0.9s;
//   display: inline-flex;
//   background: #242424;
//   color: #fff;
//   flex-wrap: nowrap;
//   text-align: center;
// }
// .btn-container {
//   position: absolute;
//   display: flex;
//   margin: auto;
//   justify-content: space-evenly;
//   align-content: center;
// }
// .left-btn {
//   position: absolute;
//   left: 2px;
//   top: 35%;
//   z-index: 10;
//   background: #fff;
//   /* border: 1px solid #dcdcdc63;
//   box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16); */
// }
// .right-btn {
//   position: absolute;
//   right: 15px;
//   top: 35%;
//   z-index: 10;
//   background: #fff;
//   /* border: 1px solid #dcdcdc63;
//   box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16); */
// }
// .prevslide,
// .nextslide {
//   background: #ffffff;
//   border: none;
//   border-radius: 20%;
//   cursor: pointer;
//   display: flex;
//   font-size: 4rem;
//   color: #1a9a16;
//   opacity: 0.7;
//   justify-items: center;
//   box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
//   transition: all 0.3s;
//   align-items: center;

//   &:hover {
//     opacity: 1;
//   }
// }

// .icon {
//   fill: #444444;
//   height: 3rem;
//   width: 3rem;
// }
