@import "../main.scss";
.products-container-main {
  position: relative;
  width: 80%;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @include respond(tab-port) {
    width: 90%;
  }

  .category-name {
    font-size: $l3-font-size;
    font-weight: 900;
    color: #253d4e;
  }

  .products {
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    margin: auto;
    width: 100%;
  }

  .product-container {
    flex-direction: column;
    border: 1px solid rgba(112, 112, 112, 0.14);
    display: flex;
    padding: 1.5rem;
    border-radius: 15px;
    width: 100%;
    max-width: 21rem;
    user-select: none;
    text-decoration: none;
    margin: auto 2% auto 0;
    cursor: pointer;
    transition: all 0.8s;
    a {
      text-decoration: none;
    }
    &:hover {
      border: 1px solid #47b752;
    }
    @include respond(tab-land) {
      margin-right: 2rem;
      width: 18rem;
      &:hover {
        border: 1px solid rgba(112, 112, 112, 0.14);
      }
    }
    .product-image {
      display: flex;
      margin: auto;
      height: 10rem;
      justify-content: center;
      img {
        width: auto;
        height: 100%;
      }
      @include respond(tab-port) {
        height: 8rem;
      }
    }

    .product-name {
      margin-top: 1rem;
      height: 4rem;
      font-size: 1.5rem;
      font-weight: 500;
      color: rgb(43, 43, 43);

      @include respond(big-desktop) {
        font-size: 1.6rem;
      }
      @include respond(tab-port) {
        line-height: 1.2;
      }
    }

    .product-detail {
      height: 2rem;
      text-decoration: none;
      .product-detail-txt {
        margin-top: 1rem;
        font-weight: 500;
        color: #848484;
        font-size: 1.4rem;

        @include respond(tab-port) {
          margin-top: 0.5rem;
        }
      }
    }

    .product-price-with-btn {
      margin-top: 3rem;
      display: flex;
      align-items: center;
      justify-content: space-between;

      @include respond(tab-port) {
        margin-top: 1rem;
      }

      .product-price {
        font-size: 1.8rem;
        font-family: inherit;
        font-weight: 500;
        color: #000;
      }

      .add-to-cart {
        display: flex;
        border-radius: 6px;
        background: transparent;
        color: #47b752;
        font-weight: 600;
        border: 1px solid #47b752;
        cursor: pointer;
        max-width: 90%;
        align-items: center;
        padding: 0.9rem 2.1rem 0.9rem 2.1rem;
        height: 35px;
        text-align: center;
        justify-content: center;
        box-sizing: border-box;
        transition: all 0.1s;
        opacity: 0.9;

        &:hover {
          opacity: 1;
        }

        @include respond(tab-port) {
          margin-top: 1rem;
        }
      }
    }
  }
}

.enable {
  display: flex;
}
@include incr-decr;
.none {
  display: none !important;
}
.category-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;

  a {
    color: #269931;
    font-weight: 500;
    font-size: 1.8rem;
  }
}
