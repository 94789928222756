.loginFixed {
  position: fixed;
  display: flex;
  width: 100%;
  height: 100%;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.466);

  .login-container {
    width: 30%;
    border-radius: 3px;
    margin: auto;
    font-size: 1.6rem;
    display: flex;
    top: 10rem;
    flex-direction: column;
    align-items: center;
    border: none;
    left: 15rem;
    background: #fff;
    box-shadow: 0px 3px 99px rgba(0, 0, 0, 0.16);

    @include respond(tab-land) {
      width: 90%;
    }
    .login-header {
      text-align: center;
      padding: 5rem;
      align-items: center;
      display: flex;
      font-size: 2.4rem;
      height: 7rem;
    }
    .login-body {
      display: flex;
      font-size: 2rem;
      align-items: center;
      flex-direction: column;
      text-align: center;
      width: 100%;
      margin: auto;
      height: 28rem;
      padding-bottom: 1rem;
      @include respond(tab-land) {
        width: 90%;
      }
      .caption {
        color: #000;
        margin-bottom: 4rem;
      }
      .loginInputContainer {
        display: flex;
        font-size: 2rem;
        border: 1px solid #707070;
        width: 100%;
        padding: 1rem;
        margin-bottom: 3rem;
        input {
          font-size: 2rem;
          border: none;
          outline: none;
          background: transparent;
          width: 90%;
          margin-left: 1rem;
          height: 100%;
        }
      }
      .next-btn {
        width: 100%;
        font-size: 1.8rem;
        height: 40px;
        background: rgba(203, 203, 203, 0.71);
        border: 1px solid #707070;
      }
    }
  }
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
