@import "../main.scss";
.featured-cat-container {
  margin: auto;
  display: block;
  width: 80%;
  @include respond(tab-port) {
    width: 90%;
  }

  .headings {
    font-size: $l3-font-size;
    font-weight: 800;
    color: #253d4e;
  }

  .category-section {
    display: flex;
    margin-top: 3rem;
    flex-wrap: wrap;
    justify-content: space-between;

    @media screen and (min-width: 400px) and (max-width: 750px) {
      justify-content: start;
    }
    .category-container {
      min-width: 10rem;
      @include respond(tab-land) {
        min-width: 0;
        margin-right: 1vw;
      }
      .category {
        justify-content: center;
        flex-direction: column;
        cursor: pointer;
        width: 8rem;
        display: flex;
        margin-top: 1rem;
        margin-bottom: 1rem;
        align-items: center;

        figcaption {
          text-align: center;
          margin-top: 0.5rem;
          font-size: 1.4rem;
          font-weight: 600;
          color: rgba(85, 85, 85, 0.908);
        }
        img {
          width: 100%;
          @include respond(tab-port) {
            width: 5rem;
          }
        }
      }
    }
  }

  .promo-container {
    display: flex;

    .promo {
      position: relative;
      img {
        width: 95%;
      }

      .shop-now-with-arr {
        position: absolute;
        bottom: 2rem;
        left: 3rem;
        display: flex;
        background-color: #ff6f36;
        width: -moz-fit-content;
        width: fit-content;
        padding: 0.5rem 0.5rem 0.5rem 1.8rem;
        border-radius: 5px;

        a {
          color: $white-color;
          text-decoration: none;
          font-size: $default-font-size;
        }

        .arrs {
          color: $white-color;
          font-size: 3rem;
          margin-bottom: -1rem;
        }
      }
    }
  }
}
