@import "../main.scss";
.all-products-wrap {
  position: relative;
  width: 90%;
  margin: 5vh auto;
  display: flex;
  justify-content: space-between;
  align-items: start;
  font-family: inherit;
  @include respond(tab-port) {
    width: 100%;
  }
}
.products-container-wrapper {
  display: flex;
  flex-wrap: wrap;
  width: 70%;
  margin: 0 auto auto 20vw;
  overflow: auto;
  @include respond(tab-port) {
    margin-right: 0 !important;
    margin-left: auto !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .product-wrapper {
    flex-direction: column;
    border: 1px solid rgba(112, 112, 112, 0.14);
    display: flex;
    padding: 1.5rem;
    border-radius: 15px;
    width: 100%;
    min-width: 20rem;
    max-width: 20rem;
    user-select: none;
    text-decoration: none;
    margin: auto 2% auto 0;
    cursor: pointer;
    transition: all 0.8s;
    @include respond(tab-port) {
      min-width: 18rem;
      max-width: 18rem;
    }
  }

  .product-image {
    display: flex;
    margin: auto;
    height: 10rem;
    justify-content: center;
    img {
      width: auto;
      height: 100%;
    }
    @include respond(tab-port) {
      height: 8rem;
    }
  }

  .product-name {
    margin-top: 1rem;
    height: 4rem;
    font-size: 1.5rem;
    font-weight: 500;
    color: rgb(43, 43, 43);

    @include respond(big-desktop) {
      font-size: 1.6rem;
    }
    @include respond(tab-port) {
      line-height: 1.2;
    }
  }

  .product-detail {
    height: 2rem;
    text-decoration: none;
    .product-detail-txt {
      margin-top: 1rem;
      font-weight: 500;
      color: #848484;
      font-size: 1.4rem;

      @include respond(tab-port) {
        margin-top: 0.5rem;
      }
    }
  }
}
.product-price-with-btn {
  margin-top: 3rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include respond(tab-port) {
    margin-top: 1rem;
  }

  .product-price {
    font-size: 1.8rem;
    font-family: inherit;
    font-weight: 500;
    color: #000;
  }

  .add-to-cart {
    display: flex;
    border-radius: 6px;
    background: transparent;
    color: #47b752;
    font-weight: 600;
    border: 1px solid #47b752;
    cursor: pointer;
    max-width: 90%;
    align-items: center;
    padding: 0.9rem 2.1rem 0.9rem 2.1rem;
    height: 35px;
    text-align: center;
    justify-content: center;
    box-sizing: border-box;
    transition: all 0.1s;
    opacity: 0.9;

    &:hover {
      opacity: 1;
    }

    @include respond(tab-port) {
      margin-top: 1rem;
    }
  }
}
.tabs-container {
  @include respond(tab-port) {
    width: 10rem !important;
    background-color: #fff;
    margin: 0 !important;
    top: 20rem;
  }
}
.tab-label {
  text-transform: none !important;
  font-size: 1.4rem !important;
  display: flex;
  justify-content: start;
  align-items: start !important;

  @include respond(tab-port) {
    width: 2.5% !important;
    font-size: 1.2rem !important;
  }
}
.category {
  display: flex;
  width: 2rem;

  img {
    width: 10%;
  }
}
