*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  font-size: 62.5%; //1 rem = 10px

  @include respond(tab-land) {
    //width < 1200?
    //1rem = 8px, 8/16 =50%
    font-size: 56.25%;
  }

  @include respond(tab-port) {
    //width < 900?
    font-size: 50%;
    //  1rem = 8px, 8/16 =50%
  }
  @include respond(laptop) {
    //width < 900?
    font-size: 60%; //1rem = 8px, 8/16 =50%
  }

  @include respond(big-desktop) {
    font-size: 75%; //1rem = 12px, 12/16 =50%
  }
}

//
a {
  text-decoration: none;
}
