.getLocation {
  position: fixed;
  display: flex;
  width: 100%;
  height: 100%;
  bottom: 0;
  left: 0;
  transition: all 2s;
  justify-content: center;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.466);

  .location-container {
    position: absolute;
    width: 35%;
    margin: auto;
    transition: all 2s;
    font-size: 1.6rem;
    display: flex;
    top: 9rem;
    flex-direction: column;
    align-items: center;
    border: none;
    background: #fff;

    box-shadow: 0px 3px 99px rgba(0, 0, 0, 0.16);
    right: 14rem;
    @include respond(tab-port) {
      position: relative;
      width: 95%;
      right: 0;
      top: -10rem;
    }
    .location-header {
      text-align: center;
      padding: 5rem;
      align-items: center;
      display: flex;
      font-size: 2.4rem;
      height: 0;
      @include respond(tab-land) {
        height: 7rem;
      }
    }
    .location-body {
      align-items: center;
      display: flex;
      font-size: 2rem;
      height: 10rem;
      justify-content: space-between;
      margin: auto;
      justify-content: center;
      position: relative;
      text-align: center;
      margin-bottom: 2rem;
      width: 100%;
      @include respond(tab-land) {
        width: 90%;
        flex-direction: column;
        height: 20rem;
        padding-bottom: 4rem;
      }
      .detection-btn {
        width: 35%;
        min-width: 15rem;
        border-radius: 2px;
        height: 4rem;
        cursor: pointer;
        background: #2aa62a;
        color: #fff;
        font-size: 1.5rem;
        letter-spacing: 1px;
        border: none;
        outline: none;

        @include respond(tab-land) {
          width: 90%;
        }
      }

      .caption {
        margin-right: 3rem;

        @include respond(tab-land) {
          margin-right: 0;
        }
      }
      .locationInputContainer {
        border-radius: 5px;
        position: relative;
        width: 85%;
        display: flex;
        align-items: center;
        @include respond(tab-land) {
          display: flex;
          flex-direction: column-reverse;
          width: 100%;
          border: none;
        }
        .searchLoc {
          position: relative;
          width: 70%;
          @include respond(tab-land) {
            width: 90%;
          }
          input {
            background: transparent;
            outline: none;
            width: 100%;
            border: 1px solid #b9b9b9;
            padding: 1rem;
          }

          .autocomplete-main {
            position: absolute;
            overflow: auto;
            z-index: 99;
            top: 4.5rem;
            width: 100%;
            left: 0;
            right: 0;
            height: auto;
            @include respond(tab-port) {
              height: 15rem;
            }
          }
          .autocomplete-container {
            padding: 5.5px;
            overflow: auto;
            font-weight: 500;
            font-size: 1.4rem;
            cursor: pointer;
            width: 100%;
            text-align: left;
            background-color: #fff;
            border: 1px solid #d4d4d4;

            &:hover {
              background-color: #e4e4e4;
            }
          }
          .autocomplete-items div:hover {
            /*when hovering an item:*/
            background-color: #e9e9e9;
          }
          .autocomplete-active {
            /*when navigating through the items using the arrow keys:*/
            background-color: DodgerBlue !important;
            color: #ffffff;
          }
        }
        .or {
          border: 0.2px solid grey;
          border-radius: 50%;
          padding: 0.1rem 0.5rem 0.1rem 0.5rem;
          font-size: 1.7rem;
          margin: 1.2rem;

          @include respond(tab-port) {
            margin-top: 1.5rem;
            margin-bottom: 1.5rem;
          }
        }
      }
    }
  }
}
