@import "../main.scss";
.account {
  width: 70%;
  margin: 5vh auto;
  box-shadow: 0 0 8px 0 rgb(0, 0, 0, 0.1);
  min-height: 50rem;

  @include respond(tab-port) {
    width: 90%;
  }
}
.userData {
  display: flex;
  margin: 5vh auto;
  width: 60%;
  font-size: 1.4rem;
  width: 30rem;
  flex-direction: column;
}
.userData > div {
  font-size: 1.6rem;
  margin-bottom: 2.5rem;
}
