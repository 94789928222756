// Colors

$primary-color: #ff6f36;
$secondary-color: #fff;
$light-grey: #555454;

$black: #000000;
$white: #ffffff;
$white-color: #ffffff;

$color-secondary-light: #ffb900;
$color-secondary-dark: #ff7730;

$color-grey-light-1: #f7f7f7;

//font
$default-font-size: 1.6rem;
$l3-font-size: 2rem;

// Fonts
$font-sm: 1.6rem;
$font-med: 1.8rem;
$font-big: 2rem;
$font-big-1: 2.4rem;
$font-big-2: 2.4rem;
$font-big-3: 2.6rem;
