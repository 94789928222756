@import "../main.scss";
@import "../layouts/beforeFooter.scss";

.footer {
  display: flex;
  width: 80%;
  justify-content: space-between;
  margin: auto;
  font-family: inherit;

  @include respond(tab-port) {
    width: 90%;
    flex-direction: column;
    justify-content: space-between;
    margin: auto;
    padding-bottom: 5rem;
  }

  .footer-section-container {
    .footer-heading {
      font-size: $default-font-size;
      margin-bottom: 1.4rem;
      @include respond(tab-port) {
        margin-top: 2rem;
      }
    }
    .foot-sec-1 {
      display: flex;
      flex-direction: column;
      font-size: 1.4rem;

      @include respond(tab-port) {
        width: 90%;
        flex-wrap: wrap;
        align-items: center;
        flex-direction: row;
      }

      .footer-ico {
        color: $primary-color;
        height: 2rem;
        width: 2rem;
      }
      span {
        font-size: 1.4rem;
        font-weight: 600;
      }

      a {
        text-decoration: none;
        color: rgb(24, 24, 24);
        margin: auto 1rem auto 0;
      }
    }
  }
  .down-space {
    a {
      margin-bottom: 1rem;

      @include respond(tab-port) {
        margin-top: 2rem;
      }
    }
  }
}
