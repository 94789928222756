@import "./Assets/Styles/main.scss";
@import "./Assets/Styles/base/typography";
@import "./Assets/Styles/base/base.scss";
@import "./Assets/Styles/base/utility";
@import "./Assets/Styles/abstracts/reusables.scss";

.mainWrapper {
  display: flex;
  flex-direction: column;
  margin: auto;
  max-width: 100%;
  @include respond(v-big-desktop) {
    width: fit-content;
  }
}
