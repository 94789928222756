@import "../main.scss";

.description-section {
  width: 80%;
  margin: auto;
  justify-content: space-evenly;
  .description-Wrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding-bottom: 4rem;
    font-size: 1.6rem;

    @include respond(tab-port) {
      flex-direction: column;
      width: 100%;
      padding-bottom: 7rem;
    }
  }

  .product-image {
    border: 1px solid #d8d8d88e;
    border-radius: 2px;
    display: flex;
    height: 45rem;
    padding: 1rem;
    img {
      width: 100%;
    }
    @include respond(tab-port) {
      margin-bottom: 2rem;
      height: 15rem;
      width: 15rem;
      padding: 1rem;
    }
  }

  .main-description-container {
    display: flex;
    flex-direction: column;
    margin-left: 3%;
    width: 70%;

    @include respond(tab-port) {
      width: 100%;
    }
    .product-heading {
      margin-bottom: 2rem;

      .product-name {
        color: $light-grey;
        font-weight: 600;
        font-size: 1.8rem;
      }
    }
    .description-title {
      color: $light-grey;
      margin-bottom: 2rem;
      font-weight: 600;
    }
    .description-container {
      margin-bottom: 2rem;
      width: 90%;
      height: auto;
      max-height: 15rem;
      overflow: auto;
      @include respond(tab-port) {
        width: 100%;
      }
      .description {
        color: $light-grey;
      }
    }
    .details-title {
      color: $light-grey;
      margin-bottom: 2rem;
      font-weight: 600;
    }
    .details-container {
      display: flex;
      width: 50%;
      flex-direction: column;
      @include respond(tab-port) {
        width: 100%;
      }
      .detail {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 2rem;
        font-weight: 500;

        .abbrev {
          width: 40%;
          font-weight: 600;
          color: $light-grey;
        }
        .answer {
          width: 60%;
          font-size: 1.6rem;
          font-weight: bold;
          color: #373347;
          font-family: inherit;
        }
      }
    }

    .add-to-cart {
      padding: 1rem 2rem;
      background: #47b752;
      border-radius: 5px;
      font-family: $font-med;
      font-weight: 700;
      width: 40%;
      border: 0;
      color: #fff;
      cursor: pointer;
      opacity: 0.85;
      transition: all 0.25s;

      &:hover {
        opacity: 1;
        background-color: #47b752;
      }

      @include respond(tab-port) {
        width: 90%;
      }
    }
  }
}
